<template>
  <v-row>
    <v-dialog persistent max-width="450" v-model="deleteDialog">
      <v-card>
        <v-card-title class="headline primary white--text">
          {{ $t('confirmation') }}
        </v-card-title>

        <v-card-text class="pt-2">
          <v-row>
            <v-col cols="12">
              {{ $t('page_company_sales_goal_confirmation_text') }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red"
            text
            @click="deleteDialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="confirmDelete()"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-container class="px-0 py-0">
            <v-row align="center">
              <v-col cols="auto" class="text-reference">
                {{ $t('reference') }}:
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="getCompanyBusinessYears"
                  item-value="term"
                  item-text="term"
                  v-model="selectedTerm"
                  solo
                  flat
                  dense
                  class="term-select"
                  background-color="term-select"
                  color="primary"
                  hide-details
                  append-icon="$customChevronDown"
                >
                  <template v-slot:item="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{
                      $t('year_label', {
                        number: item.term,
                        start: item.start_date.slice(0, -3),
                        end: item.end_date.slice(0, -3)
                      })
                    }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <SalesGoalSetting :term="selectedTerm" @delete="handleDelete" />
    </v-col>
  </v-row>
</template>

<script>
import SalesGoalSetting from '@/components/admin/partials/Company/Detail/FLGoal/SalesGoalSetting'
import { mapGetters } from 'vuex'
export default {
  name: 'index',
  components: { SalesGoalSetting },
  computed: {
    ...mapGetters(['getCompanyBusinessYears', 'getCompanyBusinessYear'])
  },
  created() {
    this.selectedTerm = this.getCompanyBusinessYear.term
  },
  data() {
    return {
      loading: false,
      selectedTerm: 0,
      deleteDialog: false,
      deleteId: 0,
      type: '',
      companyId: 0
    }
  },
  methods: {
    cancelDelete() {
      this.deleteId = 0
      this.type = ''
      this.companyId = 0
    },

    confirmDelete() {
      let params = {}
      params.id = this.companyId
      params.sales_goal_id = this.deleteId

      this.loading = true
      this.$store
        .dispatch('COMPANY_SALES_GOAL_DELETE', params)
        .then(() => {
          this.deleteDialog = false
          this.$store
            .dispatch('COMPANY_SALES_GOAL_LIST_GET', {
              id: params.id
            })
            .finally(() => {
              this.loading = false
            })
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleDelete(e) {
      this.deleteDialog = true
      this.deleteId = e.id
      this.type = e.type
      this.companyId = e.company_id
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
