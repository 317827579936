<template>
  <v-card>
    <v-card-title class="px-0 pb-0">
      <v-container class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular"
              depressed
              color="secondary"
              :ripple="false"
            >
              {{ $t('page_company_sales_goal_setting_title') }}
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="auto">
            <v-btn
              text
              color="primary"
              class="text-capitalize"
              :to="{ name: 'CompanyDetailSalesCostGoalCreate' }"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('page_company_sales_goal_setting_create_text') }}
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
              icon
              @click="toggleExpand"
              :class="isExpanded ? 'rotate' : ''"
            >
              <v-icon size="16">$customChevronDown</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="pb-0">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-reference-sub">
            {{ $t('reference') }} | {{ getTermLabel() }}
          </v-col>

          <v-col cols="3">
            <v-row align="center">
              <v-col cols="3" class="text-right">
                <div class="main--text text-subheading-2 font-weight-regular">
                  {{ $t('total_sales') }}
                </div>
              </v-col>
              <v-col cols="auto">
                <div class="main--text text-heading font-weight-medium">
                  {{ getCompanySalesGoalsList.total_sale | toCurrency }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text class="px-0">
      <v-expansion-panels v-model="expanded" accordion class="elevation-0">
        <v-expansion-panel :key="0">
          <v-expansion-panel-content eager>
            <Table
              :loading="loading"
              :headers="generatedHeaders"
              :items="salesGoals"
              :footer="false"
              :total-records="salesGoals.length"
              :number-of-pages="0"
              @update:options="getDataFromApi"
            >
              <template v-slot:item="{ index, item, headers }">
                <tr class="py-2">
                  <td
                    v-for="header in headers"
                    class="py-2"
                    :class="[
                      getTdAlignClass(
                        header.itemAlign === undefined
                          ? header.align
                          : header.itemAlign
                      )
                    ]"
                    :key="'td-' + index + '-' + header.value"
                  >
                    <template v-if="header.value === 'status'">
                      <template v-if="item.status === 1">
                        <v-chip color="#D9FFEA" small>
                          <v-icon color="success" size="16">$check</v-icon>
                        </v-chip>
                      </template>
                    </template>

                    <template v-else-if="header.value === 'id'">
                      <span class="primary--text font-weight-medium">
                        {{ item.id }}
                      </span>
                    </template>

                    <template v-else-if="header.value === 'actions'">
                      <v-btn
                        v-if="item.status !== 1"
                        color="#FFE0E0"
                        icon
                        small
                        @click="deleteGoal(item.id)"
                        :disabled="loading"
                      >
                        <v-icon color="#EC6767" size="16">$cross</v-icon>
                      </v-btn>
                    </template>

                    <template v-else>
                      {{ item[header.value] | toCurrency }}
                    </template>
                  </td>
                </tr>
              </template>
            </Table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'SalesGoalSetting',
  components: { Table },
  props: {
    term: {
      type: Number,
      default: 0,
      required: true
    }
  },
  watch: {
    term: {
      handler(val) {
        if (val !== 0) this.getDataFromApi()
      }
    }
  },
  data() {
    return {
      loading: false,
      expanded: 0,
      headers: [
        {
          text: '',
          align: 'right',
          value: 'status',
          sortable: false,
          width: 50
        },
        { text: this.$t('id'), align: 'left', value: 'id', width: 100 },
        {
          text: this.$t('page_company_sales_goal_setting_table_header_y_goal'),
          align: 'center',
          value: 'y_goal',
          sortable: false
        },
        { text: '', align: 'center', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['getCompanySalesGoalsList', 'getCompanyBusinessYear']),
    isExpanded() {
      return this.expanded === 0
    },
    salesGoals() {
      return this.getCompanySalesGoalsList.goals.map(salesGoal => {
        let obj = {}
        let total = 0
        for (const goal of salesGoal.monthly_goals) {
          total += goal.goal
          obj[goal.month.id] = goal.goal
        }
        return {
          id: salesGoal.id,
          y_goal: total,
          ...obj,
          status: salesGoal.status
        }
      })
    },
    generatedHeaders() {
      let headers = [...this.headers]
      if (this.getCompanySalesGoalsList.goals.length > 0) {
        for (const goal of this.getCompanySalesGoalsList.goals[0]
          .monthly_goals) {
          headers.splice(headers.length - 1, 0, {
            text: goal.month.name,
            align: 'center',
            value: goal.month.id,
            sortable: false
          })
        }
      }
      return headers
    }
  },
  methods: {
    getDataFromApi(e) {
      let params = {}
      params.term = this.term
      if (e?.sortBy[0] !== undefined) {
        let order = e.sortDesc[0] ? 'desc' : 'asc'
        params.sort = `${e.sortBy[0]}:${order}`
      }
      this.loading = true
      this.$store
        .dispatch('COMPANY_SALES_GOAL_LIST_GET', {
          id: this.$route.params.id,
          params: params
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteGoal(id) {
      return this.$emit('delete', {
        company_id: this.$route.params.id,
        id: id,
        type: 'goal'
      })
    },
    toggleExpand() {
      if (this.expanded === -1) {
        this.expanded = 0
      } else {
        this.expanded = -1
      }
    },
    getTdAlignClass(align) {
      return align !== null ? 'text-' + align : ''
    },
    getTermLabel() {
      let businessYear = this.getCompanySalesGoalsList.business_year
      return this.$t('year_label', {
        number: businessYear.term,
        start: businessYear.start_date.slice(0, -3),
        end: businessYear.end_date.slice(0, -3)
      })
    }
  }
}
</script>

<style lang="scss" src="./FLSetting.scss" scoped></style>
